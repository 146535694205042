import Hero, { HeroProps } from 'blocks/hero/hero';
import Blocks from 'components/blocks/blocks';
import SEO from 'components/seo/seo';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import imagesLoaded from 'imagesloaded';
import { handlePageLoaded } from 'components/trans/transLink';

import classNames from 'classnames';

import './page.template.scss';

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      blocks: any[];
    };
  };
}

const PageTemplate: React.FC<Props> = (props) => {
  const { title, id, seo } = props.pageContext;

  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [heroData, setHeroData] = useState<HeroProps>();

  useEffect(() => {
    const main = mainRef.current;
    !!main && imagesLoaded(document.querySelectorAll('[data-preload]'), handlePageLoaded);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    const heroes = props.data.page.blocks.filter((item) => item.name === 'acf/hero');
    if (!!heroes.length) {
      setHeroData(heroes[0].hero);
    }
  }, [props.data.page]);

  return (
    <div className={classNames('page-template', { 'is-loading-complete': isLoaded })} id={id} ref={mainRef}>
      <SEO {...seo} />
      {!!heroData && <Hero {...heroData} />}
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{title}</h5>
        </div>
        <div className="section-content page-content">
          <Blocks blocks={props.data.page.blocks} postFeeds={props.pageContext.postFeeds} glossary={props.pageContext.glossary} />
        </div>
      </section>
    </div>
  );
};
export default PageTemplate;

export const query = graphql`
  query ($id: String!, $language: String!) {
    page: wpPage(id: { eq: $id }) {
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
